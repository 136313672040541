<template>
  <div class="base-container">
    <vxe-toolbar perfect size="mini">
      <template v-slot:buttons>
        <div class="cf table-header-padding">
          <div class="fl">
            <el-button type="primary" @click="createspaceName()" size="mini" class="el-icon-plus"
              style="margin-right: 10px">
              新增仓库
            </el-button>
          </div>
          <div class="fr">
            <el-tooltip class="item" effect="dark" content="刷新" placement="top">
              <el-button size="mini" @click="searchInit()" circle class="el-icon-refresh">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="导出" placement="top">
              <el-button @click="exportDataEvent()" size="mini" class="el-icon-download" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="打印" placement="top">
              <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
      highlight-hover-row auto-resize size="small" ref="spaceName" resizable row-id="id" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow>
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
      <vxe-table-column field="id" width="80" title="ID"></vxe-table-column>
      <vxe-table-column field="spaceName" min-width="100" title="仓库名称"></vxe-table-column>
    </vxe-table>
    <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
      :page-size="page.pageSize" :total="page.totalResult" class="ext-pager"
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
      :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
    </vxe-pager>
    <detail ref="detail" @init="init()" />
  </div>
</template>

<script>
import detail from './components/detail'
import { mapGetters } from 'vuex'

export default {
  name: 'spaceName',
  data() {
    return {
      data: [],
      allAlign: 'center',
      loading: false,
      tableHeight: window.innerHeight - 190 + 'px',
      page: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
    }
  },
  components: { detail },
  computed: {
    ...mapGetters(['spaceName']),
  },
  created() {
    const _this = this
    _this.init()
    this.$store.dispatch('getSpaceName')
  },
  mounted() {
    const _this = this
    window.onresize = () => {
      if (_this.$refs.spaceName) {
        _this.tableHeight = window.innerHeight - _this.$refs.spaceName.$el.offsetTop - 62 + 'px'
      }
    }
  },
  methods: {
    // 仓库列表
    init() {
      const _this = this
      _this.$set(_this, 'loading', true)
      const params = {
        params: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
        },
      }
      _this.$axios
        .get('/order/spaceName/queryList', params)
        .then((res) => {
          if (res) {
            const data = res.data.data
            if (!['', null, undefined].includes(data.list)) {
              this.data = data.list
              this.page.currentPage = data.pageNum
              this.page.pageSize = data.pageSize
              this.page.totalResult = data.total
            }
          }
          this.loading = false
        })
        .catch((err) => {
          console.log('spaceName Err:', err)
          _this.$set(_this, 'loading', false)
        })
    },
    handlePageChange({ currentPage, pageSize }) {
      var _this = this
      _this.page.currentPage = currentPage
      _this.page.pageSize = pageSize
      _this.init()
    },
    // 导出列表
    exportDataEvent() {
      const _this = this
      _this.$refs.spaceName.openExport()
    },
    // 打印列表
    printDataEvent() {
      const _this = this
      _this.$refs.spaceName.openPrint()
    },
    // 创建仓库
    createspaceName() {
      const _this = this
      const data = { data: {}, name: '创建仓库' }
      _this.$refs.detail.display(data)
    },
  },
}
</script>

<style lang="sass" scoped>
</style>